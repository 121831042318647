/* eslint-disable no-unused-vars */
export const BASE_URL = 'https://inscripcions.incoop.cat'
export const SERVICES = [
    {
        serviceName: 'horta',
        name: "Casals i ludoteques d'Horta-Guinardó",
        imageUrl: '/images/horta/main-image.webp',
        color: '#6CC563',
        endpoint: '/horta',
        token: '1f9abbc0c97f43c7db632d764e6c3a05240c41b27129dc845355e765158dc510fca75d38e4e1e38431b0449add3d41d95f4f29faac77fac92f65bf3074d95685d640cd621c8cc7436747f78efae5a64e6f26a1ee51244bb4d1794d2ef39adac1ef5fd629284ee4c98eb7caa81bab47c5e5ac4ffa752851ca4f30dd837b4a823d',
    },
    {
        serviceName: 'mataro',
        name: 'Casals de Gent Gran de Mataró',
        imageUrl: '/images/mataro/main-image.webp',
        color: '#EFC83B',
        endpoint: '/mataro',
        token: 'b0d871028383a5055076442dfb95c85f2ce2a6bf8517b239cf222953b750e93a947d8021b31a5d033383713512d8523370d8d6e494c564790c11f967d46517a7aac66123480e7d47d5cddcc823d98de3ab65e8781867dd3aaf4f5169b636758d58d606fda02846501d29494789d9161a4a75caf16bfb73e46dd5f8a24fe5aff9',
    },
]

export const INPUT_TYPES = {
    STRING: 'string',
    DATE: 'date',
    BOOLEAN: 'boolean',
    SIGNATURE: 'signature',
    TEXTAREA: 'richtext',
    DOCUMENT: 'document',
    CHECKBOX: 'checkbox',
    COMPONENT: 'component',
    EMAIL: 'email',
    ENUMERATION: 'enumeration',
}
