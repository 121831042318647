import { getActivitiesByCenterId, getActivity, getRelatedActivities } from '../api/activities'
import { formatActivitiesDataFromApi, formatActivityDataFromApi } from '../formatter/activitiesFormatter'

export const getActivityData = async (id, serviceName, language) => {
    return new Promise((resolve, reject) => {
        getActivity(id, serviceName)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    return resolve(formatActivityDataFromApi(response.data, serviceName, language))
                }
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const getActivitiesFromCenter = async (id, serviceName, language) => {
    return new Promise((resolve, reject) => {
        getActivitiesByCenterId(id, serviceName)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    return resolve(formatActivitiesDataFromApi(response.data, serviceName, language))
                }
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const getRelatedActivitiesFromCenterId = async (id, serviceName, language) => {
    return new Promise((resolve, reject) => {
        getRelatedActivities(id, serviceName)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    return resolve(formatActivitiesDataFromApi(response.data, serviceName, language))
                }
            })
            .catch((error) => {
                return reject(error)
            })
    })
}
