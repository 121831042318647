import React from 'react'
import { Flex, Select, Text } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useHref, useNavigate } from 'react-router-dom'
import { Colors } from '../../../theme/Colors'

const Subheader = ({ centerInfo = { id: 0, name: '', locations: [] }, serviceName }) => {
    const navigate = useNavigate()
    const router = useHref()

    return (
        <Flex
            height={'60px'}
            width={'100vw'}
            backgroundColor={'white'}
            borderBottom={'1px solid #EDEDED'}
            padding={'24px'}
            alignItems={'center'}
            wrap
            justifyContent={'space-between'}>
            <Flex alignItems={'center'}>
                <Flex
                    onClick={() => {
                        if (router.includes('activity')) {
                            navigate(`/servei/${serviceName}/center/${centerInfo.id}`)
                        } else if (router.includes('center')) {
                            navigate(`/servei/${serviceName}/`)
                        }
                    }}
                    height={'44px'}
                    cursor={'pointer'}
                    width={'44px'}
                    borderRadius={'22px'}
                    alignItems={'center'}
                    border={'1px solid #EDEDED'}
                    justifyContent={'center'}>
                    <ArrowBackIcon boxSize={22} />
                </Flex>
                <Text fontSize="xl" fontWeight={'bold'} marginLeft={'10px'}>
                    {centerInfo.name || ''}
                </Text>
            </Flex>
            {!router.includes('activity') && (
                <Flex minW={'200px'} alignItems={'center'}>
                    <Select
                        value={centerInfo.id}
                        onChange={(event) => {
                            navigate(`/servei/${serviceName}/center/${event.target.value}`)
                        }}
                        color={Colors.text}>
                        {centerInfo.locations.map((location) => (
                            <option key={location.id} value={location.id} color={Colors.text}>
                                {location.name}
                            </option>
                        ))}
                    </Select>
                </Flex>
            )}
        </Flex>
    )
}

export default Subheader
