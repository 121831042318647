export const isValidDNIorNIE = (input) => {
    // Remove leading and trailing whitespaces
    input = input.trim().toUpperCase()

    // Regular expressions for DNI and NIE validation
    const dniRegex = /^[0-9]{8}[A-HJ-NP-TV-Z]$/
    const nieRegex = /^[XYZ][0-9]{7}[A-HJ-NP-TV-Z]$/

    if (dniRegex.test(input)) {
        // Validate DNI
        const letter = input.charAt(8)
        const numberPart = parseInt(input.substr(0, 8), 10)

        return letter === 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(numberPart % 23)
    } else if (nieRegex.test(input)) {
        // Validate NIE
        const nieLetterMapping = {
            X: 0,
            Y: 1,
            Z: 2,
        }
        const firstLetter = input.charAt(0)
        const modifiedNie = nieLetterMapping[firstLetter] + input.substr(1, 7)
        const letter = input.charAt(8)
        const numberPart = parseInt(modifiedNie, 10)

        return letter === 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(numberPart % 23)
    }

    return false // Invalid format
}

export const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{9}$/
    return phoneRegex.test(phone)
}
