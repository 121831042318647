import React from 'react'
import { Box, Flex, Spinner } from '@chakra-ui/react'
import Header from '../components/atoms/Header/Header'
import Footer from '../components/atoms/Footer/Footer'
import Subheader from '../components/atoms/Subheader/Subheader'
import { useHref } from 'react-router-dom'

const MainLayout = ({ children, home, centerInfo, serviceName, isLoading = false }) => {
    const router = useHref()

    return (
        <>
            <Box width="100vw" position="fixed" top="0px" zIndex={1}>
                <Header />
                {(router.includes('center') || router.includes('activity')) && !router.includes('enrollment') && (
                    <Subheader centerInfo={centerInfo} serviceName={serviceName} />
                )}
            </Box>
            {home ? (
                <Flex paddingTop={'60px'} h={'100%'} flexDirection={'column'}>
                    <Flex flex={1} width={'100%'} flexDirection="column">
                        {children}
                    </Flex>
                    <Footer />
                </Flex>
            ) : isLoading ? (
                <Flex
                    width={'100%'}
                    flex={1}
                    overflow={'auto'}
                    alignItems={'center'}
                    minHeight={'100vh'}
                    paddingTop={'140px'}
                    flexDirection={'column'}>
                    <Flex
                        maxWidth="1200px"
                        flex={1}
                        width={'100%'}
                        flexDirection="column"
                        marginBottom={'40px'}
                        justifyContent={'center'}
                        alignItems={'center'}>
                        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.400" size="xl" />
                    </Flex>
                    <Footer />
                </Flex>
            ) : (
                <Flex
                    width={'100%'}
                    flex={1}
                    overflow={'auto'}
                    alignItems={'center'}
                    minHeight={'100vh'}
                    paddingTop={'140px'}
                    flexDirection={'column'}>
                    <Flex maxWidth="1200px" flex={1} width={'100%'} flexDirection="column" marginBottom={'40px'}>
                        {children}
                    </Flex>
                    <Footer />
                </Flex>
            )}
        </>
    )
}

export default MainLayout
