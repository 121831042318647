import { Box, Flex, Heading, Link, Stack, StackDivider, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineHome, AiOutlineMail, AiOutlineMobile } from 'react-icons/ai'
import { Colors } from '../../../theme/Colors'
import { useTranslation } from 'react-i18next'

const ContactInformation = ({ email = '', phone = '', adress = '' }) => {
    const fontSizeHeading = useBreakpointValue({ base: 'xs', sm: 'xs' })
    const fontSizeText = useBreakpointValue({ base: 'xs', sm: 'md' })
    const { t } = useTranslation()

    return (
        <Flex flex={1} width={'100%'} minW={[0, 0, '300px']} flexDirection="column" justifyContent="center">
            <Box p={8} borderWidth="1px" borderRadius={12}>
                <Stack divider={<StackDivider />} spacing="4">
                    <Flex>
                        <Flex alignItems="center">
                            <AiOutlineMail size={32} strokeWidth={25} style={{ color: Colors.brand['300'] }} />
                        </Flex>
                        <Flex flexDirection="column" ml={4}>
                            <Heading size={fontSizeHeading}>{t('contactCenter.mail')}</Heading>
                            <Link href={`mailto:${email}`} target={'_blank'} fontSize={fontSizeText}>
                                {email}
                            </Link>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Flex alignItems="center">
                            <AiOutlineMobile size={32} strokeWidth={25} style={{ color: Colors.brand['300'] }} />
                        </Flex>
                        <Flex flexDirection="column" ml={4}>
                            <Heading size={fontSizeHeading}>{t('contactCenter.phone')}</Heading>
                            <Link href={`tel:${phone}`} target={'_blank'} fontSize={fontSizeText}>
                                {phone}
                            </Link>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Flex alignItems="center">
                            <AiOutlineHome size={32} strokeWidth={25} style={{ color: Colors.brand['300'] }} />
                        </Flex>
                        <Flex flexDirection="column" ml={4}>
                            <Heading size={fontSizeHeading}>{t('contactCenter.adress')}</Heading>
                            <Link
                                href={`https://maps.google.com/?q=${adress}`}
                                target={'_blank'}
                                fontSize={fontSizeText}>
                                {adress}
                            </Link>
                        </Flex>
                    </Flex>
                </Stack>
            </Box>
        </Flex>
    )
}
export default ContactInformation
