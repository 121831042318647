import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import { Box, Flex } from '@chakra-ui/react'
import InformationContainer from '../../components/sections/InformationContainer/InformationContainer'
import ContactInformation from '../../components/sections/ContactInformation/ContactInformation'
import ActivitiesSection from '../../components/sections/ActivitiesSection/ActivitiesSection'
import { useParams } from 'react-router-dom'
import { getActivitiesFromCenter } from '../../../data/repositories/activitiesRepository'
import { getCenterDetails, getCentersData } from '../../../data/repositories/centerRepository'
import { useTranslation } from 'react-i18next'

const Center = () => {
    const { i18n } = useTranslation()
    const { centerId, serviceName } = useParams()
    const [activities, setActivities] = useState([])
    const [centerInfo, setCenterInfo] = useState({})
    const [centersLocations, setCentersLocations] = useState([])

    const getActivitiesFromRepository = () => {
        getActivitiesFromCenter(centerId, serviceName, i18n.language)
            .then((response) => {
                setActivities(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getCenterInfo = () => {
        getCenterDetails(centerId, serviceName, i18n.language)
            .then((response) => {
                setCenterInfo(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getCenters = () => {
        getCentersData(serviceName)
            .then((response) => {
                setCentersLocations(response)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        getCenterInfo()
        getActivitiesFromRepository()
        getCenters()
    }, [centerId, i18n.language])

    return (
        <MainLayout
            centerInfo={{ id: centerId, name: centerInfo.name, locations: centersLocations }}
            serviceName={serviceName}>
            <Box>
                <Flex
                    flexDirection={['column', 'column', 'row']}
                    backgroundColor={'white'}
                    align="center"
                    alignItems="flex-start"
                    marginX={[4, 4, 4, 4, 0]}
                    elevation={0}
                    borderRadius="12px"
                    p={8}>
                    <InformationContainer descriptionData={centerInfo} />
                    <ContactInformation phone={centerInfo.phone} email={centerInfo.email} adress={centerInfo.adress} />
                </Flex>
                <Flex height={'100%'} mt={6}>
                    <ActivitiesSection activityData={activities} serviceName={serviceName} />
                </Flex>
            </Box>
        </MainLayout>
    )
}

export default Center
