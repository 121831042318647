import ApiClient from '../services/axiosService'

export const getCenters = (serviceName) => {
    const URL = `/centres`
    const params = {
        'sort[0]': 'id:asc',
    }
    return ApiClient(serviceName).get(URL, { params })
}

export const getCenter = (centerId, serviceName) => {
    const URL = `/centres/${centerId}`
    const params = {
        'populate[0]': 'full_address',
        'populate[1]': 'authorization_document',
    }
    return ApiClient(serviceName).get(URL, { params })
}
