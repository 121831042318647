import moment from 'moment'
import { INPUT_TYPES } from '../../constants'

export const formatEnrollmentFields = (entity) => {
    let formattedData = []

    if (entity?.schema?.attributes) {
        const attributes = entity.schema.attributes
        for (const attributeName in attributes) {
            // eslint-disable-next-line no-prototype-builtins
            if (attributes.hasOwnProperty(attributeName)) {
                const attribute = attributes[attributeName]

                // Exclude some fields
                if (attribute.type === 'relation') {
                    continue
                }

                if (attribute.private) {
                    continue
                }

                if (attribute.name === INPUT_TYPES.SIGNATURE && attribute.type === 'string') {
                    attribute.type = INPUT_TYPES.SIGNATURE
                }

                const parsedAttribute = {
                    name: attributeName,
                    ...attribute,
                }
                formattedData.push(parsedAttribute)
            }
        }
    }

    return formattedData
}

export const formatEnrollmentByIdNumber = (entity) => {
    return {
        idNumber: entity.idNumber,
        birthdate: moment(entity.birthdate).format('DD/MM/YYYY'),
        name: entity.name,
        activityId: entity.activityId,
    }
}

export const formatEnrollment = (activityId, enrollmentData, files) => {
    const formattedDataFields = enrollmentData.map((field) => {
        if (field.type === 'date') {
            return {
                name: field.name,
                type: field.type,
                value: moment(field.value).format('DD/MM/YYYY'),
                ...(field.component && { component: field.component }),
            }
        } else if (field.name === 'id_number') {
            return {
                name: field.name,
                type: field.type,
                value: field.value
                    .trim()
                    .replace(/-|_|\s/g, '')
                    .toUpperCase(),
                ...(field.component && { component: field.component }),
            }
        } else {
            return {
                name: field.name,
                type: field.type,
                value: field.value,
                ...(field.component && { component: field.component }),
            }
        }
    })

    const formData = new FormData()
    files?.forEach((fileGroup) => {
        const type = fileGroup.type
        fileGroup.files.forEach((file) => {
            formData.append(type, file, file.name)
        })
    })
    formData.append('activityId', activityId)
    formData.append('data', JSON.stringify(formattedDataFields))

    return formData
}
