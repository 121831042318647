import React, { useEffect, useState } from 'react'
import { Card, Flex, Image, SimpleGrid, Text, Tooltip } from '@chakra-ui/react'
import {
    AiFillEnvironment,
    AiOutlineTag,
    AiOutlineTeam,
    AiOutlineUser,
    AiTwotoneCalendar,
    AiTwotoneSkin,
} from 'react-icons/ai'
import { Colors } from '../../../theme/Colors'
import { useTranslation } from 'react-i18next'
import { ImCoinEuro } from 'react-icons/im'
import placeholderImage from '../../../assets/placeholders/placeholder-activity.jpeg'
import ReactMarkdown from 'react-markdown'

const ActivitySection = ({
    activityData = {
        title: '',
        description: '',
        teacher: '',
        inscriptions: 0,
        weekDays: '',
        sessionTime: '',
        image: '',
        typology: null,
        location: '',
        prices: [],
    },
}) => {
    const { t } = useTranslation()
    const [imageUrl, setImageUrl] = useState(placeholderImage)

    useEffect(() => {
        if (activityData.image) {
            setImageUrl(activityData.image)
        }
    }, [activityData.image])

    const renderPriceSection = () => {
        if (!activityData.prices || activityData.prices?.length === 0) {
            return null
        }

        return (
            <>
                <Text fontWeight={'bold'} fontSize={'xl'} as={'h2'} marginTop={'40px'}>
                    {t('activity.price')}
                </Text>
                <SimpleGrid columns={[1, 2, 3]} spacing={10} marginTop={'20px'}>
                    {activityData.prices.map((item) => {
                        const priceChildren = (
                            <Flex flexDirection={'column'} marginLeft={6}>
                                <Text fontWeight={'bold'}>{item.label}:</Text>
                                <Text>{item.price === 0 ? t('activity.price_free') : `${item.price}€`}</Text>
                            </Flex>
                        )

                        return (
                            <Flex key={item.label} flexDirection={'row'} minW={200} alignItems={'center'}>
                                <ImCoinEuro size={48} style={{ color: Colors.brand['300'] }} />
                                {item.conditions ? (
                                    <Tooltip label={item.conditions}>{priceChildren}</Tooltip>
                                ) : (
                                    priceChildren
                                )}
                            </Flex>
                        )
                    })}
                </SimpleGrid>
            </>
        )
    }

    return (
        <Card p={'40px'}>
            <Image src={imageUrl} objectFit={'cover'} w={'100%'} h={'400px'} borderRadius={'6px'} />
            <Text fontWeight={'bold'} fontSize={'3xl'} as={'h1'} marginTop={'20px'}>
                {activityData.title}
            </Text>
            <Text fontWeight={'bold'} fontSize={'xl'} as={'h2'}>
                {t('activity.description')}
            </Text>
            <Text fontSize={'20px'} as={'h3'} marginTop={'10px'} style={{ whiteSpace: 'pre-line' }}>
                <ReactMarkdown>{activityData.description}</ReactMarkdown>
            </Text>
            <Text fontWeight={'bold'} fontSize={'xl'} as={'h2'} marginTop={'20px'}>
                {t('activity.characteristics')}
            </Text>
            <SimpleGrid columns={[1, 2, 3]} spacing={10} marginTop={'20px'}>
                {activityData.teacher && (
                    <Flex flexDirection={'row'} minW={200} alignItems={'center'}>
                        <AiOutlineUser
                            size={32}
                            strokeWidth={22}
                            style={{ color: Colors.brand['300'], minWidth: 32, maxWidth: 32 }}
                        />
                        <Flex flexDirection={'column'} marginLeft={6}>
                            <Text fontWeight={'bold'}>{t('activity.teacher')}</Text>
                            <Text>{activityData.teacher}</Text>
                        </Flex>
                    </Flex>
                )}
                {activityData.inscriptions && (
                    <Flex flexDirection={'row'} minW={200} alignItems={'center'}>
                        <AiOutlineTeam
                            size={32}
                            strokeWidth={22}
                            style={{ color: Colors.brand['300'], minWidth: 32, maxWidth: 32 }}
                        />
                        <Flex flexDirection={'column'} marginLeft={6}>
                            <Text fontWeight={'bold'}>{t('activity.inscriptions')}:</Text>
                            <Text>{activityData.inscriptions}</Text>
                        </Flex>
                    </Flex>
                )}
                {activityData.weekDays && (
                    <Flex flexDirection={'row'} minW={200} alignItems={'center'}>
                        <AiTwotoneCalendar
                            size={48}
                            strokeWidth={22}
                            style={{ color: Colors.brand['300'], minWidth: 32, maxWidth: 32 }}
                        />
                        <Flex flexDirection={'column'} marginLeft={6}>
                            <Text fontWeight={'bold'}>{t('activity.weekDays')}</Text>
                            <Text>{activityData.weekDays}</Text>
                        </Flex>
                    </Flex>
                )}
                {activityData.material && (
                    <Flex flexDirection={'row'} minW={200} alignItems={'center'}>
                        <AiTwotoneSkin
                            size={52}
                            strokeWidth={22}
                            style={{ color: Colors.brand['300'], minWidth: 32, maxWidth: 32 }}
                        />
                        <Flex flexDirection={'column'} marginLeft={6}>
                            <Text fontWeight={'bold'}>{t('activity.material')}</Text>
                            <Text>{activityData.material}</Text>
                        </Flex>
                    </Flex>
                )}
                {activityData.typology && (
                    <Flex flexDirection={'row'} minW={200} alignItems={'center'}>
                        <AiOutlineTag
                            size={48}
                            strokeWidth={22}
                            style={{ color: Colors.brand['300'], minWidth: 32, maxWidth: 32 }}
                        />
                        <Flex flexDirection={'column'} marginLeft={6}>
                            <Text fontWeight={'bold'}>{t('activity.typology')}</Text>
                            <Text>{activityData.typology}</Text>
                        </Flex>
                    </Flex>
                )}
                {activityData.location && (
                    <Flex flexDirection={'row'} minW={200} alignItems={'center'}>
                        <AiFillEnvironment
                            size={32}
                            strokeWidth={22}
                            style={{ color: Colors.brand['300'], minWidth: 32, maxWidth: 32 }}
                        />
                        <Flex flexDirection={'column'} marginLeft={6}>
                            <Text fontWeight={'bold'}>{t('activity.location')}</Text>
                            <Text>{activityData.location}</Text>
                        </Flex>
                    </Flex>
                )}
            </SimpleGrid>
            {renderPriceSection()}
        </Card>
    )
}

export default ActivitySection
