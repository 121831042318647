import React, { useState } from 'react'
import { Box, Flex, Image, Link, Select, Text, useMediaQuery } from '@chakra-ui/react'
import { AiFillTwitterSquare, AiOutlineFacebook, AiOutlineInstagram, AiOutlineMobile } from 'react-icons/ai'
import { Colors } from '../../../theme/Colors'
import logo_incoop from '../../../assets/logo/logo-incoop.png'
import { useTranslation } from 'react-i18next'

const Header = () => {
    const { t, i18n } = useTranslation()
    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
        setSelectedLanguage(event.target.value)
    }

    return (
        <>
            <Box backgroundColor={'black'} height="60px">
                <Flex justifyContent={'space-between'} backgroundColor={'black'} height="60px">
                    <Flex mr={10} alignItems={'center'}>
                        <Link href="https://incoop.cat/" target="_blank">
                            <Image ml={8} boxSize="100px" objectFit="contain" src={logo_incoop} alt="Dan Abramov" />
                        </Link>
                    </Flex>
                    <Flex mr={10} alignItems={'center'}>
                        <AiOutlineMobile size={24} style={{ color: Colors.brand['400'], marginRight: '10px' }} />
                        <Link
                            href="tel:933026162"
                            mr={4}
                            size="xs"
                            textTransform="uppercase"
                            color={'white'}
                            fontWeight="bold">
                            93 302 61 62
                        </Link>
                        {isLargerThan800 && (
                            <>
                                <Text fontSize={16} fontWeight={'bolder'} color={'white'} mr={4}>
                                    ·
                                </Text>
                                <Flex minWidth={'140px'} color={'white'} alignItems={'center'} mr={4}>
                                    <Select
                                        value={selectedLanguage}
                                        onChange={changeLanguage}
                                        color={'white'}
                                        placeholder={'Selecciona un idioma'}>
                                        <option value="ca" color={Colors.text}>
                                            {t('global.ca')}
                                        </option>
                                        <option value="es" color={Colors.text}>
                                            {t('global.es')}
                                        </option>
                                        <option value="en" color={Colors.text}>
                                            {t('global.en')}
                                        </option>
                                    </Select>
                                </Flex>
                                <Text fontSize={16} fontWeight={'bolder'} color={'white'} mr={4}>
                                    ·
                                </Text>
                                <Link href="https://www.facebook.com/incoopserveis/" target="_blank">
                                    <AiOutlineFacebook
                                        size={24}
                                        style={{ color: Colors.brand['400'], marginRight: '10px' }}
                                    />
                                </Link>
                                <Link href="https://twitter.com/incoopserveis?lang=es" target="_blank">
                                    <AiFillTwitterSquare
                                        size={24}
                                        style={{ color: Colors.brand['400'], marginRight: '10px' }}
                                    />
                                </Link>
                                <Link href="https://www.instagram.com/incoop_serveis/" target="_blank">
                                    <AiOutlineInstagram
                                        size={24}
                                        style={{ color: Colors.brand['400'], marginRight: '10px' }}
                                    />
                                </Link>
                            </>
                        )}
                    </Flex>
                </Flex>
            </Box>
        </>
    )
}

export default Header
