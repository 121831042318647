import React from 'react'
import './App.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Colors } from './presentation/theme/Colors'
import Home from './presentation/screens/home/home'
import Center from './presentation/screens/center/center'
import Activity from './presentation/screens/activity/activity'
import SelectCenter from './presentation/screens/selectCenter/selectCenter'
import Inscription from './presentation/screens/inscription/inscription'

const App = () => {
    const theme = extendTheme({
        colors: Colors,
    })

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Home />,
        },
        {
            path: '/servei/:serviceName/',
            element: <SelectCenter />,
        },
        {
            path: '/servei/:serviceName/center/:centerId',
            element: <Center />,
        },
        {
            path: '/servei/:serviceName/activity/:activityId',
            element: <Activity />,
        },
        {
            path: '/servei/:serviceName/activity/:activityId/enrollment',
            element: <Inscription />,
        },
    ])

    return (
        <ChakraProvider theme={theme}>
            <RouterProvider router={router} />
        </ChakraProvider>
    )
}

export default App
