import ApiClient from '../services/axiosService'

export const enrollUserWithIdNumber = ({ idNumber, birthdate, name, activityId }, serviceName) => {
    const URL = '/enrollment/quick-enroll'
    const data = {
        idNumber,
        birthdate,
        name,
        activityId,
    }

    return ApiClient(serviceName).post(URL, data)
}

export const postEnrollUser = (data, serviceName) => {
    const URL = '/enrollment/enroll'
    return ApiClient(serviceName).post(URL, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const getEnrollmentUserFields = (serviceName) => {
    const URL = '/content-type-builder/content-types/api::client.client'
    return ApiClient(serviceName).get(URL)
}

export const getEnrollmentActivityFields = (serviceName) => {
    const URL = '/content-type-builder/content-types/api::enrollment.enrollment'
    return ApiClient(serviceName).get(URL)
}

export const getEnrollmentComponentFields = (componentUid, serviceName) => {
    const URL = `/content-type-builder/components/${componentUid}`
    return ApiClient(serviceName).get(URL)
}
