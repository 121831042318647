import {
    enrollUserWithIdNumber,
    getEnrollmentComponentFields,
    getEnrollmentUserFields,
    postEnrollUser,
} from '../api/enrollments'
import { formatEnrollment, formatEnrollmentByIdNumber, formatEnrollmentFields } from '../formatter/enrollmentFormatter'

const getComponentFields = async (components, serviceName) => {
    try {
        const promises = components.map(async (component) => {
            const result = await getEnrollmentComponentFields(component.component, serviceName)
            if (result.status === 200 && result.data) {
                const componentFields = formatEnrollmentFields(result.data.data)
                componentFields.forEach((field) => {
                    field.component = component.name
                })

                return componentFields
            } else {
                throw new Error('Error getting component')
            }
        })

        return await Promise.all(promises)
    } catch (error) {
        console.error(error)
    }
}

export const getEnrollmentFields = async (serviceName) => {
    const result = {
        userFields: [],
        // activityFields: [],
    }

    const enrollmentUserFieldsResponse = await getEnrollmentUserFields(serviceName)
    if (enrollmentUserFieldsResponse.status === 200 && enrollmentUserFieldsResponse.data) {
        result.userFields = formatEnrollmentFields(enrollmentUserFieldsResponse.data.data)

        // Get components
        const components = result.userFields.filter((field) => field.type === 'component')
        const componentFields = await getComponentFields(components, serviceName)

        componentFields.forEach((fields) => {
            const componentIndex = result.userFields.findIndex((component) => component.name === fields[0].component)
            result.userFields.splice(componentIndex, 1, ...fields)
        })
    }

    // const enrollmentActivityFieldsResponse = await getEnrollmentActivityFields()
    // if (enrollmentActivityFieldsResponse.status === 200 && enrollmentActivityFieldsResponse.data) {
    //     result.activityFields = formatEnrollmentFields(enrollmentActivityFieldsResponse.data.data)
    // }

    return result
}

export const enrollUserByIdNumber = async (idNumber, birthdate, name, activityId, serviceName) => {
    return new Promise((resolve, reject) => {
        const formattedIdNumber = idNumber
            .trim()
            .replace(/-|_|\s/g, '')
            .toUpperCase()

        enrollUserWithIdNumber(
            formatEnrollmentByIdNumber({ idNumber: formattedIdNumber, birthdate, name, activityId }),
            serviceName,
        )
            .then((response) => {
                if (response.status === 200) {
                    resolve(response)
                } else throw new Error('Error enrolling user')
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const enrollUser = async (activityId, enrollmentData, files, serviceName) => {
    return new Promise((resolve, reject) => {
        const formData = formatEnrollment(activityId, enrollmentData, files)

        postEnrollUser(formData, serviceName)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response)
                } else throw new Error('Error enrolling user')
            })
            .catch((error) => {
                reject(error)
            })
    })
}
