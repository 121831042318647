import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../theme/Colors'
import { AiOutlineTeam, AiTwotoneCalendar } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import placeholderImage from '../../../assets/placeholders/placeholder-activity.jpeg'

const ActivityCard = ({ image, schedule, places, name, description, key, id, serviceName }) => {
    const navigation = useNavigate()
    const { t } = useTranslation()

    const [imageUrl, setImageUrl] = useState(placeholderImage)

    useEffect(() => {
        if (image) {
            setImageUrl(image)
        }
    }, [image])

    const handleOnClick = () => {
        navigation(`/servei/${serviceName}/activity/${id}`)
        location.reload()
    }

    return (
        <Box
            key={key}
            height={'100%'}
            borderRadius="12px"
            overflow="hidden"
            backgroundColor="white"
            transition={'box-shadow'}
            transitionDuration={'0.2s'}
            cursor={'pointer'}
            _hover={{
                boxShadow: 'var(--chakra-shadows-xl)',
            }}
            onClick={handleOnClick}>
            <Image maxH={200} w={'100%'} src={imageUrl} objectFit="cover" justifyContent={'start'} />
            <Box m={4}>
                <Box flexDirection={'column'}>
                    <Heading
                        fontSize={'26px'}
                        textOverflow={'ellipsis'}
                        whiteSpace={'nowrap'}
                        overflow={'hidden'}
                        color={Colors.text}>
                        {name}
                    </Heading>
                    <Text mt={2} mb={2} noOfLines={2}>
                        {description}
                    </Text>
                    <Flex flexDirection={'row'} my={2}>
                        <AiOutlineTeam size={24} style={{ color: Colors.brand['300'], flexShrink: 0 }} />
                        <Text ml={1} mr={3}>{`${places} ${t('activity.inscriptions').toLowerCase()}`}</Text>
                    </Flex>
                    <Flex flexDirection={'row'} my={2}>
                        <AiTwotoneCalendar size={24} style={{ color: Colors.brand['300'], flexShrink: 0 }} />
                        <Text ml={1}>{schedule}</Text>
                    </Flex>
                </Box>
                <Button
                    mt={2}
                    onClick={handleOnClick}
                    backgroundColor="brand.400"
                    color="white"
                    borderRadius="50px"
                    _hover={{ backgroundColor: 'brand.500' }}
                    variant="solid"
                    width="100%">
                    <Text>{t('activity.see_details')}</Text>
                </Button>
            </Box>
        </Box>
    )
}

export default ActivityCard
