import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import { Button, Card, Divider, Flex, Grid, GridItem, Hide, Image, Link, Show, Text, useToast } from '@chakra-ui/react'
import ActivitySection from '../../components/sections/ActivitySection/ActivitySection'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { getActivityData, getRelatedActivitiesFromCenterId } from '../../../data/repositories/activitiesRepository'
import Logo from '../../assets/logo/logo_negro.png'
import FormInputs from '../../components/atoms/FormInputs/FormInputs'
import { INPUT_TYPES } from '../../../constants'
import { Colors } from '../../theme/Colors'
import ActivitiesSection from '../../components/sections/ActivitiesSection/ActivitiesSection'
import { enrollUserByIdNumber } from '../../../data/repositories/enrollmentRepositories'
import Chip from '../../components/atoms/Chip/Chip'
import { Trans } from 'react-i18next'
import moment from 'moment'
import { isValidDNIorNIE } from '../../../utils/functions'

const Activity = () => {
    const toast = useToast()
    const { activityId, serviceName } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [activityData, setActivityData] = useState({
        title: '',
        description: '',
        teacher: '',
        inscriptions: 0,
        weekDays: '',
        sessionTime: '',
        image: '',
        normalPrice: 0,
        monoparentalPrice: 0,
        numerousFamilyPrice: 0,
    })
    const [dni, setDni] = useState('')
    const [birthday, setBirthday] = useState('')
    const [name, setName] = useState('')
    const [relatedActivities, setRelatedActivities] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [isWarningOpen, setIsWarningOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [requiredActivityId, setRequiredActivityId] = useState(null)
    const { i18n } = useTranslation()

    const getActivityInfo = () => {
        getActivityData(activityId, serviceName, i18n.language)
            .then((data) => {
                let newData = { ...data }
                setActivityData(newData)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getRelatedActivities = () => {
        if (serviceName === 'horta') return
        getRelatedActivitiesFromCenterId(activityData.centerId, serviceName)
            .then((data) => {
                setRelatedActivities(data.slice(0, 3))
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleEnrollment = () => {
        if (dni === '' || birthday === '' || name === '') return

        const formattedIdNumber = dni
            .trim()
            .replace(/-|_|\s/g, '')
            .toUpperCase()

        if (isValidDNIorNIE(formattedIdNumber)) {
            enrollUserByIdNumber(dni, birthday, name, activityId, serviceName)
                .then(() => {
                    setIsOpen(true)
                })
                .catch((err) => {
                    if (err.response.status === 406) {
                        toast({
                            title: t('activity.warning_maximum_courses_signed_title'),
                            description: t('activity.warning_maximum_courses_signed_description'),
                            status: 'warning',
                            duration: 6000,
                            isClosable: true,
                        })
                    } else if (err.response.status === 409) {
                        setIsWarningOpen(true)
                    } else if (err.response.status === 424) {
                        setRequiredActivityId(err.response.data.necessaryActivityId)
                    } else {
                        navigate(`/servei/${serviceName}/activity/${activityId}/enrollment`, {
                            state: {
                                dni: dni,
                                birthday: birthday,
                                name: name,
                            },
                        })
                    }
                })
        } else {
            toast({
                title: t('activity.warning_invalid_id_number'),
                description: t('activity.warning_invalid_id_number_description'),
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        getActivityInfo()
    }, [i18n.language])

    useEffect(() => {
        if (activityData.centerId) {
            getRelatedActivities()
        }
    }, [activityData, i18n.language])

    const renderEnrollmentSection = () => {
        let cardContent = null
        const { enrollment_start_date: enrollmentStartDate, enrollment_finish_date: enrollmentFinishDate } =
            activityData

        if (
            ((moment().isAfter(enrollmentStartDate) && moment().isBefore(enrollmentFinishDate)) ||
                enrollmentStartDate === null) &&
            !requiredActivityId
        ) {
            cardContent = (
                <>
                    <Flex p={'10px'} my={5} flex={1} alignItems={'center'} flexDirection={'column'}>
                        <Image src={Logo} objectFit={'contain'} width={'100%'} maxWidth={'200px'} />
                        <Text
                            mt={'20px'}
                            as={'h2'}
                            fontSize={'26px'}
                            fontWeight={'600'}
                            textAlign={'center'}
                            color={Colors.text}>
                            {t('activity.form_title')}
                        </Text>
                    </Flex>
                    <Divider color={Colors.divider} height={'4px'} />
                    <Flex p={'20px'} flex={1} alignItems={'center'} flexDirection={'column'}>
                        <form
                            style={{ width: '100%' }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleEnrollment()
                            }}>
                            <FormInputs
                                type={INPUT_TYPES.STRING}
                                name={'name'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder={t('activity.form_name_placeholder')}
                                required
                                label={
                                    serviceName === 'horta'
                                        ? t('activity.form_name_label_horta')
                                        : t('activity.form_name_label')
                                }
                            />
                            <FormInputs
                                type={INPUT_TYPES.STRING}
                                name={'id_number'}
                                value={dni}
                                onChange={(e) =>
                                    setDni(
                                        e.target.value
                                            .trim()
                                            .toUpperCase()
                                            .replace(/-|_|\s/g, ''),
                                    )
                                }
                                placeholder={t('activity.form_id_number_placeholder')}
                                required
                                label={
                                    serviceName === 'horta'
                                        ? t('activity.form_id_number_label_horta')
                                        : t('activity.form_id_number_label')
                                }
                            />
                            {serviceName === 'horta' && (
                                <Text as={'span'} fontSize={'12px'} mb={'8px'}>
                                    {t('activity.form_minor_id_warning')}
                                </Text>
                            )}
                            <FormInputs
                                type={INPUT_TYPES.DATE}
                                name={'birthDate'}
                                required
                                value={birthday}
                                onChange={(event) => {
                                    setBirthday(event.target.value)
                                }}
                                label={
                                    serviceName === 'horta'
                                        ? t('activity.form_birth_date_label_horta')
                                        : t('activity.form_birth_date_label')
                                }
                            />
                            {serviceName === 'horta' && (
                                <Text as={'span'} fontSize={'12px'} mb={'8px'}>
                                    {t('activity.form_minor_birthdate_warning')}
                                </Text>
                            )}
                            <Button
                                type={'submit'}
                                width={'100%'}
                                disabled={dni === '' || birthday === ''}
                                height={'50px'}
                                mt={'20px'}
                                mb={'24px'}
                                borderRadius={'25px'}
                                backgroundColor={'brand.400'}
                                color={'white'}
                                _hover={'brand.500'}>
                                {t('global.next')}
                            </Button>
                        </form>
                    </Flex>
                </>
            )
        } else if (requiredActivityId) {
            cardContent = (
                <div>
                    <Chip
                        isOpen
                        color={'#FAF089'}
                        textColor={Colors.text}
                        message={
                            <div>
                                {t('activity.warning_enrollment_required_activity')}
                                <Link
                                    href={`/servei/${serviceName}/activity/${requiredActivityId}`}
                                    color={Colors.link}>
                                    {t('activity.warning_enrollment_required_activity_link')}
                                </Link>
                            </div>
                        }></Chip>
                </div>
            )
        } else if (moment().isBefore(enrollmentStartDate)) {
            cardContent = (
                <div>
                    <Chip
                        message={
                            t('activity.warning_enrollment_before_date') +
                            ' ' +
                            moment(enrollmentStartDate).format('DD/MM/YYYY')
                        }
                        isOpen
                        color={'#FAF089'}
                        textColor={Colors.text}
                    />
                </div>
            )
        } else {
            cardContent = (
                <div>
                    <Chip
                        message={<Trans t={t} i18nKey={'activity.warning_enrollment_out_of_date'} />}
                        isOpen
                        color={'#FAF089'}
                        textColor={Colors.text}
                    />
                </div>
            )
        }

        return (
            <GridItem colSpan={[3, 3, 3, 1]}>
                <Card>{cardContent}</Card>
            </GridItem>
        )
    }

    const warningMessageMataro = `
            <p>Benvolguts/des,</p>
            </br>
            <p>Aquest curs 2024-25 hem canviat la forma de com inscriure's als tallers i activitats dels Casals de Gent Gran de Matar&oacute;, i a partir d'ara &eacute;s fara a trav&eacute;s de la pr&ograve;pia plataforma de l'Ajuntament de Matar&oacute;. Per accedir a la plataforma d'inscripci&oacute; cliqueu sobre aquest enlla&ccedil;: <a style="text-decoration: underline; color: #0060a1" target="_blank" href="https://seu.mataro.cat/opencms/opencms/sede/contenedores/tramitOVAC.html?tipusTramitCodi=INSC-ActivitatsGentGran">https://seu.mataro.cat/opencms/opencms/sede/contenedores/tramitOVAC.html?tipusTramitCodi=INSC-ActivitatsGentGran</a></p>
            </br>
            <p><b>Fase preinscripcions (entre el 3 i el 13 de setembre 2024)</b></p>
            </br>
            <p><b>INSCRIPCI&Oacute; PER INTERNET</b></p>
            <ul style="list-style-position: inside; padding-left: 20px;">
                <li>A nivell digital, les persones trobaran el tr&agrave;mit web a la Seu Electr&ograve;nica de l'Ajuntament.</li>
                <li>No es requereix certificat digital, per tant, qualsevol persona es pot inscriure a trav&eacute;s de qualsevol ordinador o tel&egrave;fon m&ograve;bil intel&middot;ligent.</li>
            </ul>
            </br>
            <p><b>INSCRIPCI&Oacute; PER INTERNET</b>
            <ul style="list-style-position: inside; padding-left: 20px;">
                <li>A nivell presencial: les persones podran formalitzar les preinscripcions als dispositius i amb el personal del Casals de Gent Gran de Matar&oacute;.</li>
                <li>Tamb&eacute; podran dirigir-se a qualsevol OFIAC/Espai connecta de la ciutat, i a trav&eacute;s dels punts d'autoservei que es troben ubicats als diferents centres c&iacute;vics.</li>
                <li>Tamb&eacute; a nivell presencial per atenci&oacute; directa, trobaran l'Espai Connecta (Pla&ccedil;a de la Muralla, 21, de 9 a 15h), on us atendran personalment.</li>
            </ul>
            </br>
            <p>Mentre la fase de preinscripcions estigui oberta, les modificacions que les persones vulguin fer sobre la seva petici&oacute; inicial es podran gestionar a trav&eacute;s de qualsevol OFIAC/Espai Connecta o trucant, de forma gratu&iuml;ta, al 010.</p>
            </br>
            <p><b>Fase sorteig de places:</b></br>L'Ajuntament, un cop finalitzat el termini de preinscripcions, ser&agrave; el propi Ajuntament qui far&agrave; el sorteig p&uacute;blic de places i constituir&agrave; les noves llistes d'espera.</p>
            </br>
            <p><b>Fase comunicaci&oacute; de les persones amb pla&ccedil;a i la llista d'espera</b></br>Un cop es disposi dels llistats definitius, des dels Casals es contactar&agrave; amb les persones que han resultat amb pla&ccedil;a adjudicada per tal d'informar del procediment de matr&iacute;cula. Les llistes completes quedaran publicades a la web municipal i als panells informatius dels diferents Casals.</p>
    `;


    return (
        <MainLayout
            centerInfo={{ id: activityData.centerId, name: activityData.title, locations: [] }}
            serviceName={serviceName}
            isLoading={isLoading}>

            <Hide below="lg">
                <Flex display={isOpen ? 'flex' : 'none'} mx={[4, 4, 4, 4, 0]} mb={[0, 0, 0, 0, 4]}>
                    <Chip
                        message={
                            <Trans
                                values={{ name: activityData?.title }}
                                t={t}
                                i18nKey={'activity.registered_message'}
                            />
                        }
                        isOpen={isOpen}
                    />
                </Flex>
                <Flex display={isWarningOpen ? 'flex' : 'none'} mx={[4, 4, 4, 4, 0]} mb={[0, 0, 0, 0, 4]}>
                    <Chip
                        message={<Trans t={t} i18nKey={'activity.enrollment_existing'} />}
                        isOpen={isWarningOpen}
                        color={'#FAF089'}
                        textColor={Colors.text}
                    />
                </Flex>
            </Hide>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} p={[4, 4, 4, 4, 0]}>
                <GridItem colSpan={[3, 3, 3, 2]}>
                    <ActivitySection activityData={activityData} />
                </GridItem>
                {serviceName === 'horta' && renderEnrollmentSection()}
            </Grid>
            {serviceName !== 'horta' && (
                <Flex display={'flex'} mx={[4, 4, 4, 4, 0]} mb={[0, 0, 0, 0, 4]} style={{ marginTop: 24 }}>
                    <Chip
                        message={(
                            <div dangerouslySetInnerHTML={{ __html: warningMessageMataro }} />
                        )}
                        isOpen={true}
                        color={'#b8d5ea'}
                        textColor={Colors.text}
                        textAlign={'left'}
                    />
                </Flex>
            )}
            <Show below="lg">
                <Flex display={isOpen ? 'flex' : 'none'} mx={[4, 4, 4, 4, 0]}>
                    <Chip
                        message={
                            <Trans
                                values={{ name: activityData?.title }}
                                t={t}
                                i18nKey={'activity.registered_message'}
                            />
                        }
                        isOpen={isOpen}
                    />
                </Flex>
                <Flex display={isWarningOpen ? 'flex' : 'none'} mx={[4, 4, 4, 4, 0]}>
                    <Chip
                        message={<Trans t={t} i18nKey={'activity.enrollment_existing'} />}
                        isOpen={isWarningOpen}
                        color={'#FAF089'}
                        textColor={Colors.text}
                    />
                </Flex>
            </Show>
            {serviceName !== 'horta' && (
                <>
                    <Text fontSize={'2xl'} fontWeight={'bold'} mx={[4, 4, 4, 4, 0]} mt={'30px'} mb={'16px'}>
                        {t('activity.anotherCourses')}
                    </Text>
                    <Flex>
                        <ActivitiesSection activityData={relatedActivities} serviceName={serviceName} />
                    </Flex>
                </>
            )}
        </MainLayout>
    )
}

export default Activity
