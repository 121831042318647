import axios from 'axios'
import { BASE_URL, SERVICES } from '../../constants'

const getEnvironmentConfig = (serviceName) => {
    const service = SERVICES.find((service) => service.serviceName === serviceName)
    return { endpoint: service.endpoint, token: service.token }
}

export default (serviceName) => {
    const { endpoint, token } = getEnvironmentConfig(serviceName)

    return axios.create({
        baseURL: BASE_URL + endpoint + '/api',
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
}
