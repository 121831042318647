import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import { Box, Flex, Heading, Hide, Image, SimpleGrid, Text } from '@chakra-ui/react'
import logo from '../../assets/logo/logo_negro.png'
import CenterButton from '../../components/atoms/CenterButton/Button'
import { getCentersData } from '../../../data/repositories/centerRepository'
import { useNavigate, useParams } from 'react-router-dom'
import { SERVICES } from '../../../constants'

const SelectCenter = () => {
    const navigate = useNavigate()
    const { serviceName } = useParams()
    const [centerData, setCenterData] = useState([])
    const getCenter = () => {
        getCentersData(serviceName)
            .then((response) => {
                setCenterData(response)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        getCenter()
    }, [])

    const CenterLocations = () => {
        return centerData?.map((location, index) => (
            <CenterButton
                route={`/servei/${serviceName}/center`}
                key={index}
                text={location?.name}
                color={location?.color}
                locationId={location?.id}
            />
        ))
    }

    return (
        <MainLayout home serviceName={serviceName}>
            <Flex height={'100%'} width={'100%'}>
                <Hide below={'md'}>
                    <Flex width={'100%'}>
                        <Image
                            src={SERVICES.find((service) => service.serviceName === serviceName)?.imageUrl}
                            height={'100%'}
                            width={'100%'}
                            objectFit={'cover'}
                        />
                    </Flex>
                </Hide>
                <Box backgroundColor={'white'} width={'100%'}>
                    <Box mx={[4, 4, 8, 16]} mt={[4, 4, 8, 16]} flexDirection={'column'}>
                        <Image
                            src={logo}
                            onClick={() => {
                                navigate('/')
                            }}
                            cursor={'pointer'}
                        />
                        <Box>
                            <Flex mt={6}>
                                <Heading color={'text'}>
                                    <Text key={0}>
                                        {SERVICES.find((service) => service.serviceName === serviceName)?.name}
                                    </Text>
                                </Heading>
                            </Flex>
                        </Box>
                        <SimpleGrid columns={[1, 1, 2]} spacing={4} mt={[4, 4, 8]} mb={4} w={'100%'}>
                            <CenterLocations />
                        </SimpleGrid>
                    </Box>
                </Box>
            </Flex>
        </MainLayout>
    )
}

export default SelectCenter
