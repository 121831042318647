import ApiClient from '../services/axiosService'

export const getActivitiesByCenterId = (centerId, serviceName) => {
    const params = {
        'populate[0]': 'cover',
        'populate[1]': 'localizations',
        'filters[center]': centerId,
        'filters[active]': true,
        'pagination[pageSize]': 500,
    }

    const URL = `/activities`
    return ApiClient(serviceName).get(URL, { params })
}

export const getActivity = (activityId, serviceName) => {
    const params = {
        populate: '*',
    }

    const URL = `/activities/${activityId}`
    return ApiClient(serviceName).get(URL, { params })
}

export const getRelatedActivities = (centerId, serviceName) => {
    return getActivitiesByCenterId(centerId, serviceName)
}
