import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'

const InformationContainer = ({ descriptionData }) => {
    return (
        <Flex flex={[1, 1, 2]} flexDirection={'column'} mr={[0, 0, 15]} mb={[15, 15, 0]}>
            <Text fontSize={'3xl'} fontWeight={'bold'}>
                {descriptionData?.name}
            </Text>
            <Text fontSize="md" style={{ whiteSpace: 'pre-line' }}>
                <ReactMarkdown>{descriptionData?.description}</ReactMarkdown>
            </Text>
        </Flex>
    )
}
export default InformationContainer
