import React, { useCallback } from 'react'
import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import { Colors } from '../../../theme/Colors'
import { Trans, useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { DeleteIcon } from '@chakra-ui/icons'
import { DragZone } from '../DragZone/DragZone'

const DragZoneManager = ({ files, onSelectFile }) => {
    const { t } = useTranslation()

    const onDrop = useCallback((acceptedFiles) => {
        onSelectFile(acceptedFiles)
    }, [])
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({ onDrop: onDrop })

    return files?.length > 0 ? (
        <Box
            mt={'20px'}
            padding={'20px'}
            borderWidth={'1px'}
            borderRadius={'8px'}
            borderStyle={'solid'}
            borderColor={Colors.divider}>
            <Flex justifyContent={'space-between'}>
                <Box overflowX={'hidden'}>
                    <Text fontWeight={'bold'} color={Colors.text} noOfLines={1}>
                        {files[0].name}
                    </Text>
                    <Text fontSize={'12px'} color={Colors.textSecondary}>
                        {
                            // Convert bytes to MB and express with 2 decimals
                            (files[0].size / 1024 / 1024).toFixed(2) + ' MB'
                        }
                    </Text>
                </Box>
                <Box>
                    <IconButton
                        aria-label="Delete uploaded file"
                        colorScheme="red"
                        variant="outline"
                        icon={<DeleteIcon />}
                        onClick={() => onSelectFile([])}
                    />
                </Box>
            </Flex>
        </Box>
    ) : (
        <DragZone {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            <Trans t={t} i18nKey={'inscription.drag_doc_section'} />
        </DragZone>
    )
}

export default DragZoneManager
