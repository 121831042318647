import React, { useState } from 'react'
import { Box, Flex, Grid, GridItem, Image, Link, Select, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react'
import logo_incoop from '../../../assets/logo/icono-incoop.png'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { Colors } from '../../../theme/Colors'

const Footer = () => {
    const { t } = useTranslation()
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
    const [isLessThan800] = useMediaQuery('(max-width: 800px)')

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
        setSelectedLanguage(event.target.value)
    }

    return (
        <Box width="100%" position={'relative'}>
            <Grid backgroundColor={'black'} gap={4} padding={'20px'} templateColumns={'repeat(6, 1fr)'}>
                <GridItem colSpan={[6, 6, 2]}>
                    <Flex flex={1} flexDirection={'row'}>
                        <Link href="https://incoop.cat/" target="_blank">
                            <Image objectFit="contain" height={'20px'} mr={'6px'} src={logo_incoop} alt="Dan Abramov" />
                        </Link>
                        <Flex alignItems={'center'} height={'20px'}>
                            <Text color={'white'} fontWeight={'bolder'} fontSize={14}>
                                © INCOOP SCCL{' '}
                            </Text>
                            <Text color={'white'} fontSize={14}>
                                {t('footer.text')}
                            </Text>
                            <Text color={'white'} fontSize={14}>
                                {' - '} v.1.0.0
                            </Text>
                        </Flex>
                    </Flex>
                </GridItem>
                <GridItem colSpan={[6, 6, 4]}>
                    <SimpleGrid columns={[1, 1, 2, 4]}>
                        <Link href="tel:933026162" fontSize={14} textTransform="uppercase" color={'white'}>
                            93 302 61 62
                        </Link>
                        <Link href="mailto:incoop@incoop.cat" fontSize={14} color={'white'} fontWeight={'bolder'}>
                            incoop@incoop.cat
                        </Link>
                        <Link
                            href="https://maps.google.com/?q=C/Mallorca53,Local5,08029"
                            target="_blank"
                            color={'white'}
                            fontSize={14}>
                            C/Mallorca 53, Local 5, 08029
                        </Link>
                        <Link
                            href="https://incoop.cat/politica-de-privadesa/"
                            target="_blank"
                            color={'white'}
                            fontSize={14}>
                            {t('footer.PrivacyPolicy')}
                        </Link>
                    </SimpleGrid>
                    {isLessThan800 && (
                        <Flex minWidth={'140px'} mt={4} maxWidth={'240px'} color={'white'} alignItems={'center'}>
                            <Select
                                value={selectedLanguage}
                                onChange={changeLanguage}
                                color={'white'}
                                placeholder={'Selecciona un idioma'}>
                                <option value="ca" color={Colors.text}>
                                    {t('global.ca')}
                                </option>
                                <option value="es" color={Colors.text}>
                                    {t('global.es')}
                                </option>
                                <option value="en" color={Colors.text}>
                                    {t('global.en')}
                                </option>
                            </Select>
                        </Flex>
                    )}
                </GridItem>
            </Grid>
        </Box>
    )
}

export default Footer
