import styled from 'styled-components'
import { Colors } from '../../../theme/Colors'

const getColor = (props) => {
    if (props.isDragAccept) {
        return Colors.brand['400']
    }
    if (props.isDragReject) {
        return Colors.redColor
    }
    if (props.isFocused) {
        return Colors.brand['500']
    }
    return '#eeeeee'
}

export const DragZone = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
    border-width: 2px;
    border-radius: 8px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: ${Colors.text};
    outline: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;
    }
`
