import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translation_ca from './locales/ca/common.json'
import translation_es from './locales/es/common.json'
import translation_en from './locales/en/common.json'

const resources = {
    ca: { translation: translation_ca },
    es: { translation: translation_es },
    en: { translation: translation_en },
}

i18n.use(initReactI18next).init({
    resources,
    lng: 'ca',
    supportedLngs: ['ca', 'es', 'en'],
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
