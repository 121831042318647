import { getCenter, getCenters } from '../api/centers'
import { formatCenterDataFromApi, formatCentersDataFromApi } from '../formatter/centerFormatter'

export const getCentersData = async (serviceName) => {
    return new Promise((resolve, reject) => {
        getCenters(serviceName)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    return resolve(formatCentersDataFromApi(response.data))
                }
            })
            .catch((error) => {
                return reject(error)
            })
    })
}

export const getCenterDetails = async (id, serviceName, language) => {
    return new Promise((resolve, reject) => {
        getCenter(id, serviceName)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    return resolve(formatCenterDataFromApi(response.data, serviceName, language))
                }
            })
            .catch((error) => {
                return reject(error)
            })
    })
}
