import React, { useRef } from 'react'
import { INPUT_TYPES } from '../../../../constants'
import { Flex, Input, Select, Switch, Text, Textarea } from '@chakra-ui/react'
import SignatureCanvas from 'react-signature-canvas'
import { Colors } from '../../../theme/Colors'

const FormInputs = ({ type, name, value, onChange, required, label, placeholder, options = [], defaultValue = '' }) => {
    //TODO Pass signature to parent component with refSignature.toDataURL()
    const refSignature = useRef(null)

    const Header = () => {
        return (
            <Flex width={'100%'} mt={'10px'} mb={'6px'}>
                <Text as={'span'} fontWeight={'400'} fontSize={'16px'}>
                    {label}
                </Text>
                {required && (
                    <Text as={'span'} color={'#FF0000'}>
                        *
                    </Text>
                )}
            </Flex>
        )
    }

    switch (type) {
        case INPUT_TYPES.STRING:
            return (
                <div style={{ width: '100%' }}>
                    <Header />
                    <Input
                        name={name}
                        placeholder={placeholder}
                        autoComplete={name}
                        required={required}
                        value={value}
                        onChange={(e) => onChange(e)}
                    />
                </div>
            )
        case INPUT_TYPES.EMAIL:
            return (
                <div style={{ width: '100%' }}>
                    <Header />
                    <Input
                        name={name}
                        placeholder={placeholder}
                        autoComplete={name}
                        required={required}
                        value={value}
                        type={'email'}
                        onChange={(e) => onChange(e)}
                    />
                </div>
            )
        case INPUT_TYPES.DATE:
            return (
                <div style={{ width: '100%' }}>
                    <Header />
                    <Input
                        name={name}
                        autoComplete={name}
                        required={required}
                        value={value}
                        onChange={(e) => onChange(e)}
                        type="date"
                    />
                </div>
            )
        case INPUT_TYPES.BOOLEAN:
            return (
                <div style={{ width: '100%' }}>
                    <Header />
                    <Flex alignItems={'center'}>
                        <Text fontSize="16px" fontWeight={!value ? 'bold' : 'normal'} color={Colors.text}>
                            No
                        </Text>
                        <Switch
                            size="md"
                            colorScheme="brand"
                            mx={'8px'}
                            isChecked={value}
                            onChange={(e) => onChange(e)}
                        />
                        <Text
                            fontSize="16px"
                            fontWeight={value ? 'bold' : 'normal'}
                            color={value ? Colors.brand['500'] : Colors.text}>
                            Sí
                        </Text>
                    </Flex>
                </div>
            )
        case INPUT_TYPES.TEXTAREA:
            return (
                <div style={{ width: '100%' }}>
                    <Header />
                    <Textarea name={name} required={required} value={value} onChange={(e) => onChange(e)} />
                </div>
            )
        case INPUT_TYPES.SIGNATURE:
            return (
                <div style={{ width: '100%' }}>
                    <Header />
                    <div style={{ border: '1px solid black', borderRadius: 8, maxWidth: 300 }}>
                        <SignatureCanvas ref={refSignature} penColor="black" canvasProps={{ height: 300 }} />
                    </div>
                </div>
            )
        case INPUT_TYPES.ENUMERATION:
            return (
                <div style={{ width: '100%' }}>
                    <Header />
                    <Select
                        value={value || defaultValue}
                        onChange={(event) => {
                            onChange(event)
                        }}
                        color={Colors.text}>
                        {options.map((option) => (
                            <option key={option} value={option} color={Colors.text}>
                                {option}
                            </option>
                        ))}
                    </Select>
                </div>
            )
        default:
            return null
    }
}

export default FormInputs
