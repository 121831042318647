import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import ActivityCard from '../../atoms/Card/ActivityCard'

const ActivitiesSection = ({ activityData = [], serviceName }) => {
    const CardContainer = () => {
        return activityData.map((course, index) => (
            <ActivityCard
                key={index}
                id={course?.id}
                name={course?.name}
                schedule={course?.schedules}
                image={course?.photo}
                places={course?.capacity}
                description={course?.description}
                serviceName={serviceName}
            />
        ))
    }

    return (
        <SimpleGrid columns={[1, 1, 2, 3]} marginX={[4, 4, 4, 4, 0]} spacing={6} width={'100%'}>
            <CardContainer />
        </SimpleGrid>
    )
}

export default ActivitiesSection
