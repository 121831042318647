import { BASE_URL, SERVICES } from '../../constants'

export const formatCentersDataFromApi = (centerData) => {
    let formattedData = []
    centerData.data.forEach((center) => {
        formattedData.push({
            name: center.attributes.name || '',
            color: center.attributes.color || '#a6e89e',
            id: center.id || 0,
        })
    })

    return formattedData
}

export const formatCenterDataFromApi = (centerData, serviceName, language) => {
    const serviceEndpoint = SERVICES.find((service) => service.serviceName === serviceName)?.endpoint
    const fullAddress = centerData.data?.attributes?.full_address || null
    const address = fullAddress?.address ? fullAddress.address : ''
    const city = fullAddress?.city ? ', ' + fullAddress.city : ''
    const door = fullAddress?.door ? ', ' + fullAddress.door : ''
    const floor = fullAddress?.floor ? ' - ' + fullAddress.floor : ''
    const number = fullAddress?.number ? ', ' + fullAddress.number : ''
    const zip = fullAddress?.zip ? ', ' + fullAddress.zip : ''
    const authorizationDocument = centerData.data?.attributes?.authorization_document?.data?.attributes || null

    return {
        id: centerData.id || 0,
        name:
            language === 'ca' && centerData.data?.attributes?.name
                ? centerData.data?.attributes?.name
                : language === 'es' && centerData.data?.attributes?.name_es
                ? centerData.data?.attributes?.name_es
                : language === 'en' && centerData.data?.attributes?.name_en
                ? centerData.data?.attributes?.name_en
                : centerData.data?.attributes?.name,
        description:
            language === 'ca' && centerData.data?.attributes?.description
                ? centerData.data?.attributes?.description
                : language === 'es' && centerData.data?.attributes?.description_es
                ? centerData.data?.attributes?.description_es
                : language === 'en' && centerData.centerData.data?.attributes?.description_en
                ? centerData.data?.attributes?.description_en
                : centerData.data?.attributes?.description,
        email: centerData.data?.attributes?.email || '',
        phone: centerData.data?.attributes?.phone || '',
        adress: address ? address + number + door + floor + city + zip : '',
        authorizationDocument: authorizationDocument
            ? {
                  ...authorizationDocument,
                  url: BASE_URL + serviceEndpoint + authorizationDocument.url || '',
              }
            : null,
    }
}
