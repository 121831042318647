import React from 'react'
import { Fade, Flex, Text } from '@chakra-ui/react'

const Chip = ({ isOpen = false, message, color = 'brand.400', textColor = 'white', textAlign = 'center' }) => {
    return (
        <Fade style={{ width: '100%' }} in={isOpen}>
            <Flex flex={1} borderRadius={'4px'} bg={color} p={4} justifyContent={'center'} alignItems={'center'}>
                <Text as={'h2'} textAlign={textAlign} color={textColor} fontSize={'20px'}>
                    {message}
                </Text>
            </Flex>
        </Fade>
    )
}

export default Chip
