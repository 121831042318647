import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import { Box, Button, Card, Checkbox, Flex, Image, Link, SimpleGrid, Text, useToast } from '@chakra-ui/react'
import FormInputs from '../../components/atoms/FormInputs/FormInputs'
import { enrollUser, getEnrollmentFields } from '../../../data/repositories/enrollmentRepositories'
import Logo from '../../assets/logo/logo_negro.png'
import { Trans, useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { INPUT_TYPES } from '../../../constants'
import { DownloadIcon } from '@chakra-ui/icons'
import { getCenterDetails } from '../../../data/repositories/centerRepository'
import { getActivityData } from '../../../data/repositories/activitiesRepository'
import { Colors } from '../../theme/Colors'
import DragZoneManager from '../../components/atoms/DragZoneManager/DragZoneManager'
import { getServiceData } from '../../../data/repositories/serviceRepository'
import { isValidDNIorNIE, isValidPhoneNumber } from '../../../utils/functions'

const Inscription = () => {
    const location = useLocation()
    const { t } = useTranslation()
    const { activityId, serviceName } = useParams()
    const [enrollmentFieldsBySection, setEnrollmentFieldsBySection] = useState([])
    const [enrollmentData, setEnrollmentData] = useState([])
    const [centerInfo, setCenterInfo] = useState({})
    const [serviceInfo, setServiceInfo] = useState({})
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [gpdrAccepted, setGpdrAccepted] = useState(false)
    // const [imageRightsAccepted, setImageRightsAccepted] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState('ca')
    const [idCardFiles, setIdCardFiles] = useState(null)
    const [idCardBackFiles, setIdCardBackFiles] = useState(null)
    const [healthCardFiles, setHealthCardFiles] = useState(null)
    const [authorizationFiles, setAuthorizationFiles] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const toast = useToast()
    const navigate = useNavigate()

    const obtainLanguage = async () => {
        const language = await i18n.language
        setCurrentLanguage(language)
    }

    useEffect(() => {
        obtainLanguage()
    }, [i18n.language])

    useEffect(() => {
        getActivityInfo()
        getEnrollment()
        getServiceInfo()
    }, [])

    const getServiceInfo = () => {
        getServiceData(serviceName)
            .then((data) => {
                setServiceInfo(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getActivityInfo = () => {
        getActivityData(activityId, serviceName, i18n.language)
            .then((data) => {
                getCenterInfo(data.centerId)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getCenterInfo = (centerId) => {
        getCenterDetails(centerId, serviceName, i18n.language)
            .then((response) => {
                setCenterInfo(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getEnrollment = () => {
        getEnrollmentFields(serviceName)
            .then((response) => {
                // Order enrollment fields by section
                const tmpEnrollmentFieldsBySection = {}
                response.userFields.forEach((field) => {
                    if (field.related) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (!tmpEnrollmentFieldsBySection.hasOwnProperty(field.related)) {
                            tmpEnrollmentFieldsBySection[field.related] = []
                        }
                        tmpEnrollmentFieldsBySection[field.related].push(field)
                    } else {
                        // eslint-disable-next-line no-prototype-builtins
                        if (!tmpEnrollmentFieldsBySection.hasOwnProperty('general')) {
                            tmpEnrollmentFieldsBySection['general'] = []
                        }
                        tmpEnrollmentFieldsBySection['general'].push(field)
                    }
                })

                setEnrollmentFieldsBySection(tmpEnrollmentFieldsBySection)
                setEnrollmentData(() => {
                    let newEnrollmentData = []
                    response.userFields.forEach((field) => {
                        let value = ''
                        if (location.state.dni && field.name === 'id_number') value = location.state.dni
                        else if (location.state.birthday && field.name === 'birthday') value = location.state.birthday
                        else if (location.state.name && field.name === 'name') value = location.state.name

                        newEnrollmentData.push({
                            ...field,
                            value: value,
                        })
                    })
                    return newEnrollmentData
                })
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const validateFields = () => {
        const idNumber = enrollmentData.find((field) => field.name === 'id_number')?.value || ''
        const phone = enrollmentData.find((field) => field.name === 'phone')?.value || ''
        const secondaryPhone = enrollmentData.find((field) => field.name === 'secondary_phone')?.value || ''

        if (!isValidDNIorNIE(idNumber)) {
            toast({
                title: t('activity.warning_invalid_id_number'),
                description: t('activity.warning_invalid_id_number_description'),
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            return false
        }

        if (!isValidPhoneNumber(phone)) {
            toast({
                title: t('activity.warning_invalid_phone_number'),
                description: t('activity.warning_invalid_phone_number_description'),
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            return false
        }

        if (secondaryPhone && !isValidPhoneNumber(secondaryPhone)) {
            toast({
                title: t('activity.warning_invalid_secondary_phone_number'),
                description: t('activity.warning_invalid_phone_number_description'),
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
            return false
        }

        return true
    }

    const handleEnrollment = () => {
        if (validateFields()) {
            if (gpdrAccepted && termsAccepted) {
                setIsLoading(true)
                const enrollmentDataToSend = [...enrollmentData]
                enrollmentDataToSend.push({
                    name: 'accept_image_rights',
                    type: 'boolean',
                    value: false,
                })
                enrollmentDataToSend.push({
                    name: 'accept_gdpr',
                    type: 'boolean',
                    value: gpdrAccepted,
                })

                const filesToSend = []
                if (idCardFiles) {
                    filesToSend.push({
                        type: 'id_card',
                        files: idCardFiles,
                    })
                }
                if (idCardBackFiles) {
                    filesToSend.push({
                        type: 'id_card_back',
                        files: idCardBackFiles,
                    })
                }
                if (healthCardFiles) {
                    filesToSend.push({
                        type: 'health_card',
                        files: healthCardFiles,
                    })
                }
                if (authorizationFiles) {
                    filesToSend.push({
                        type: 'authorization',
                        files: authorizationFiles,
                    })
                }

                enrollUser(activityId, enrollmentDataToSend, filesToSend, serviceName)
                    .then((data) => {
                        console.log(data)
                        toast({
                            title: t('inscription.toast_title_success'),
                            description: t('inscription.toast_description_success'),
                            status: 'success',
                            duration: 4000,
                            isClosable: true,
                        })

                        setIsLoading(false)
                        setTimeout(() => {
                            navigate(-1)
                        }, 4000)
                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                        toast({
                            title: t('inscription.toast_title_error'),
                            description: t('inscription.toast_description_error'),
                            status: 'error',
                            duration: 6000,
                            isClosable: true,
                        })
                    })
            } else {
                setIsLoading(false)
                toast({
                    title: t('inscription.toast_title_terms'),
                    description: t('inscription.toast_description_terms'),
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        }
    }

    const renderFields = (field, index) => {
        const fieldIndex = enrollmentData.findIndex((enrollmentField) => enrollmentField.name === field.name)

        return (
            <FormInputs
                name={field.name}
                value={enrollmentData[fieldIndex].value}
                onChange={(event) =>
                    setEnrollmentData((prevState) => {
                        let newData = [...prevState]
                        if (field.type === INPUT_TYPES.BOOLEAN) {
                            newData[fieldIndex].value = event.target.checked
                        } else {
                            newData[fieldIndex].value = event.target.value
                        }
                        return newData
                    })
                }
                type={field.type}
                label={
                    currentLanguage === 'ca'
                        ? field.label_ca
                        : currentLanguage === 'es'
                        ? field.label_es
                        : field.label_en
                }
                key={index}
                required={field.required}
                options={field.enum}
                defaultValue={field.default}
            />
        )
    }

    const renderSectionFields = (sectionName, sectionData, index) => {
        let localeTag = 'general_section'
        switch (sectionName) {
            case 'general':
                localeTag = 'general_section'
                break
            case 'school':
                localeTag = 'school_section'
                break
            case 'medical':
                localeTag = 'medical_section'
                break
            case 'authorizations':
                localeTag = 'authorizations_section'
                break
        }

        return (
            <SimpleGrid width={'100%'} columns={[1, 1, 2, 2]} spacing={4} mt={'60px'}>
                <Box key={index}>
                    <Text fontSize={'20px'} fontWeight={'bold'}>
                        <Trans t={t} i18nKey={`inscription.${localeTag}`} />
                    </Text>
                </Box>
                <Box key={index + 'anex'} />
                {sectionData.map((field, index) => renderFields(field, index))}
            </SimpleGrid>
        )
    }

    const renderAuthorizationSection = () => {
        if (centerInfo?.authorizationDocument === null) {
            return null
        }

        return (
            <SimpleGrid width={'100%'} columns={[1]} spacing={4} mt={'60px'}>
                <Box>
                    <Text fontSize={'20px'} fontWeight={'bold'}>
                        <Trans t={t} i18nKey={`inscription.authorization_section`} />
                    </Text>
                </Box>
                <Box>
                    <Text fontSize={'16px'} mb={'16px'}>
                        {t('inscription.authorization_description').replace('{emailAddress}', centerInfo?.email || '')}
                    </Text>
                    <a
                        href={centerInfo?.authorizationDocument?.url || ''}
                        download
                        target={'_blank'}
                        rel={'noopener noreferrer'}>
                        <Button leftIcon={<DownloadIcon />} colorScheme="brand" variant="outline">
                            {t('inscription.download')}
                        </Button>
                    </a>
                    <DragZoneManager
                        files={authorizationFiles}
                        onSelectFile={(selectedFiles) => setAuthorizationFiles(selectedFiles)}
                    />
                </Box>
            </SimpleGrid>
        )
    }

    const renderIdDocumentSection = () => {
        if (!serviceInfo?.requestDniFile) {
            return null
        }

        return (
            <SimpleGrid width={'100%'} columns={[1]} spacing={4} mt={'60px'}>
                <Box>
                    <Text fontSize={'20px'} fontWeight={'bold'}>
                        <Trans t={t} i18nKey={`inscription.id_document_section`} />
                    </Text>
                </Box>
                <Box>
                    <Text fontSize={'16px'} mb={'16px'}>
                        {t('inscription.id_document_description')}
                    </Text>
                    <DragZoneManager
                        files={idCardFiles}
                        onSelectFile={(selectedFiles) => setIdCardFiles(selectedFiles)}
                    />
                </Box>
                <Box>
                    <Text fontSize={'16px'} mb={'16px'}>
                        {t('inscription.id_document_back_description')}
                    </Text>
                    <DragZoneManager
                        files={idCardBackFiles}
                        onSelectFile={(selectedFiles) => setIdCardBackFiles(selectedFiles)}
                    />
                </Box>
            </SimpleGrid>
        )
    }

    const renderHealthCardSection = () => {
        if (!serviceInfo?.requestHealthCardFile) {
            return null
        }

        return (
            <SimpleGrid width={'100%'} columns={[1]} spacing={4} mt={'60px'}>
                <Box>
                    <Text fontSize={'20px'} fontWeight={'bold'}>
                        <Trans t={t} i18nKey={`inscription.health_card_section`} />
                    </Text>
                </Box>
                <Box>
                    <Text fontSize={'16px'} mb={'16px'}>
                        {t('inscription.health_card_description')}
                    </Text>
                    <DragZoneManager
                        files={healthCardFiles}
                        onSelectFile={(selectedFiles) => setHealthCardFiles(selectedFiles)}
                    />
                </Box>
            </SimpleGrid>
        )
    }

    return (
        <MainLayout serviceName={serviceName}>
            <form
                style={{ width: '100%' }}
                onSubmit={(e) => {
                    e.preventDefault()
                    handleEnrollment()
                }}>
                <Card alignItems={'center'} p={'20px'}>
                    <Flex width={'200px'}>
                        <Image src={Logo} objectFit={'contain'} alt={'logo'} width={'100%'} height={'100%'} />
                    </Flex>
                    <Text as={'h2'} fontSize={'24px'} fontWeight={'normal'} mt={'20px'} mb={'20px'}>
                        <Trans t={t} i18nKey={'inscription.title'} />
                    </Text>
                    {Object.keys(enrollmentFieldsBySection).map((section, index) =>
                        renderSectionFields(section, enrollmentFieldsBySection[section], index),
                    )}
                    {renderIdDocumentSection()}
                    {renderHealthCardSection()}
                    {renderAuthorizationSection()}
                    <Box width={'100%'} mt={'40px'} mb={'20px'}>
                        <Flex flexDirection={'column'}>
                            <Text fontSize={'20px'} fontWeight={'bold'}>
                                <Trans t={t} i18nKey={'inscription.gpdr_agreement'} />
                            </Text>
                            <Checkbox
                                colorScheme={'green'}
                                value={termsAccepted}
                                onChange={(event) => setTermsAccepted(event.target.checked)}
                                mt={'8px'}>
                                <Text as={'span'} fontWeight={'400'} fontSize={'16px'}>
                                    <Trans t={t} i18nKey={'inscription.privacy_text_checkbox'} />
                                    <Link
                                        href="https://incoop.cat/politica-de-privadesa/"
                                        target="_blank"
                                        color={Colors.link}>
                                        <Trans t={t} i18nKey={'inscription.privacy_text_link'} />
                                    </Link>
                                    <Text as={'span'} color={'#FF0000'}>
                                        *
                                    </Text>
                                </Text>
                            </Checkbox>
                            <Checkbox
                                colorScheme={'green'}
                                value={gpdrAccepted}
                                onChange={(event) => setGpdrAccepted(event.target.checked)}
                                mt={'8px'}>
                                <Text as={'span'} fontWeight={'400'} fontSize={'16px'}>
                                    <Trans t={t} i18nKey={'inscription.storage_agreement'} />
                                    <Text as={'span'} color={'#FF0000'}>
                                        *
                                    </Text>
                                </Text>
                            </Checkbox>
                            {/*<Checkbox*/}
                            {/*    colorScheme={'green'}*/}
                            {/*    value={imageRightsAccepted}*/}
                            {/*    onChange={(event) => setImageRightsAccepted(event.target.checked)}*/}
                            {/*    mt={'8px'}>*/}
                            {/*    <Text as={'span'} fontWeight={'400'} fontSize={'16px'}>*/}
                            {/*        <Trans t={t} i18nKey={'inscription.image_rights_agreement'} />*/}
                            {/*    </Text>*/}
                            {/*</Checkbox>*/}
                        </Flex>
                    </Box>
                    <Button
                        type={'submit'}
                        width={'100%'}
                        height={'50px'}
                        maxWidth={'300px'}
                        mt={'20px'}
                        mb={'20px'}
                        variant={'solid'}
                        color={'white'}
                        bg={'brand.400'}
                        _hover={'brand.500'}
                        isLoading={isLoading}
                        borderRadius={'25px'}>
                        {t('global.send')}
                    </Button>
                </Card>
            </form>
        </MainLayout>
    )
}

export default Inscription
