export const Colors = {
    brand: {
        100: '#aaeaa4',
        200: '#81dc76',
        300: '#76d76c',
        400: '#6CC563',
        500: '#2E9D58',
        600: '#2E9D58',
        700: '#2E9D58',
        800: '#2E9D58',
    },
    redColor: '#f15c5c',
    background: '#F1F1F1',
    divider: '#e0e0e0',
    link: '#329AE5',
    text: '#484848',
    textSecondary: '#939393',
}
