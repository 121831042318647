import { BASE_URL, SERVICES } from '../../constants'

export const formatActivitiesDataFromApi = (activityArray, serviceName, language) => {
    let formattedData = []
    const serviceEndpoint = SERVICES.find((service) => service.serviceName === serviceName)?.endpoint

    activityArray.data?.forEach((activity) => {
        const attributes = activity.attributes
        const locale = attributes.localizations.data.find((loc) => loc.attributes.locale === language)

        formattedData.push({
            id: activity.id || 0,
            name: locale?.attributes.name || attributes.name || '',
            schedules: locale?.attributes.schedule || attributes.schedule || '',
            capacity: locale?.attributes.seats || attributes.seats || '',
            description: locale?.attributes.description || attributes.description || '',
            photo: attributes.cover?.data?.attributes.url
                ? BASE_URL + serviceEndpoint + attributes.cover?.data?.attributes.url
                : null,
        })
    })

    return formattedData
}

export const formatActivityDataFromApi = (activity, serviceName, language) => {
    const serviceEndpoint = SERVICES.find((service) => service.serviceName === serviceName)?.endpoint

    const locale =
        language === 'ca'
            ? null
            : activity.data.attributes.localizations.data.find((loc) => loc.attributes.locale === language)

    return {
        location: activity.data?.attributes?.location || '',
        typology: locale?.attributes?.typology || activity.data?.attributes?.typology || null,
        enrollment_start_date: activity.data?.attributes?.enrollment_start_date || null,
        enrollment_finish_date: activity.data?.attributes?.enrollment_finish_date || null,
        centerId: activity.data?.attributes?.center?.data?.id || 0,
        title: locale?.attributes?.name || activity.data?.attributes?.name || '',
        description: locale?.attributes?.description || activity.data?.attributes?.description || '',
        teacher: activity.data?.attributes?.teacher || '',
        material: activity.data?.attributes?.material || '',
        inscriptions: activity.data?.attributes?.seats || 0,
        weekDays: locale?.attributes?.schedule || activity.data?.attributes?.schedule || '',
        image: activity.data?.attributes?.cover?.data?.attributes?.url
            ? BASE_URL + serviceEndpoint + activity.data?.attributes?.cover?.data?.attributes?.url
            : null,
        prices: (activity.data?.attributes?.prices || []).map((price) => ({
            id: price.id,
            label: price.label,
            price: price.price,
            conditions: price.conditions,
        })),
    }
}
