import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import { Box, Flex, Heading, Hide, Image, Link, SimpleGrid, Text } from '@chakra-ui/react'
import logo from '../../assets/logo/logo_negro.png'
import image from '../../assets/home/main-dancing.webp'
import { useTranslation } from 'react-i18next'
import CenterButton from '../../components/atoms/CenterButton/Button'
import { SERVICES } from '../../../constants'

const Home = () => {
    const { t } = useTranslation()

    const CenterButtons = () => {
        return SERVICES.map((button, index) => (
            <CenterButton
                route={'/servei'}
                key={index}
                text={button?.name}
                color={button?.color}
                locationId={button?.serviceName}
                bigButton={SERVICES.length <= 2}
            />
        ))
    }

    return (
        <MainLayout home>
            <Flex height={'100%'} width={'100%'}>
                <Hide below={'md'}>
                    <Flex width={'100%'}>
                        <Image src={image} height={'100%'} width={'100%'} objectFit={'cover'} />
                    </Flex>
                </Hide>
                <Box backgroundColor={'white'} width={'100%'}>
                    <Box mx={[4, 4, 8, 16]} mt={[4, 4, 8, 16]} flexDirection={'column'}>
                        <Image src={logo} />
                        <Box>
                            <Flex mt={6}>
                                <Heading mr={2} color={'text'}>
                                    <Text as={'span'} color={'brand.400'}>
                                        {t('home.titleService1')}
                                    </Text>
                                    {` ${t('home.titleService2')}`}
                                </Heading>
                            </Flex>
                            <Flex flexDirection={'column'} maxW={500} mt={4}>
                                <Text>
                                    {t('home.DescriptionServices')}{' '}
                                    <Link href="mailto:comunicacio@incoop.cat" color={'link'}>
                                        comunicacio@incoop.cat
                                    </Link>
                                </Text>
                            </Flex>
                        </Box>
                        <SimpleGrid
                            columns={SERVICES.length <= 2 ? 1 : [1, 1, 2]}
                            spacing={4}
                            mt={[4, 4, 8]}
                            mb={4}
                            w={'100%'}
                            maxW={SERVICES.length <= 2 ? '600px' : undefined}>
                            <CenterButtons />
                        </SimpleGrid>
                    </Box>
                </Box>
            </Flex>
        </MainLayout>
    )
}

export default Home
