import { getService } from '../api/service'
import { formatServiceFromApi } from '../formatter/serviceFormatter'

export const getServiceData = async (serviceName) => {
    return new Promise((resolve, reject) => {
        getService(serviceName)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    return resolve(formatServiceFromApi(response.data))
                }
            })
            .catch((error) => {
                return reject(error)
            })
    })
}
