import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const CenterButton = ({ text, color, locationId, route, bigButton }) => {
    const navigate = useNavigate()
    const handleNavigation = () => {
        navigate(route + `/${locationId}`)
    }

    return (
        <>
            <Flex
                w={'100%'}
                h={bigButton ? '128px' : undefined}
                p={4}
                borderRadius={8}
                backgroundColor={color}
                color={'white'}
                fontWeight={'bolder'}
                cursor={'pointer'}
                transition={'opacity'}
                transitionDuration={'0.2s'}
                _hover={{
                    opacity: 0.8,
                }}
                onClick={handleNavigation}
                alignItems={'center'}>
                <Text fontSize={bigButton ? '24px' : 'inherit'}>{text}</Text>
            </Flex>
        </>
    )
}

export default CenterButton
